<!--
 * @Date: 2024-08-26 16:31:26
 * @FilePath: \zyt-mobile-frontend\src\views\ruralFilm\pages\filmAroundDetail\index.vue
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-11 17:45:08
  * @Description: 周边放映-电影详情
-->
<template>
  <div>
    <!-- <van-nav-bar title="周边放映" left-arrow @click-left="$router.back()" /> -->
    <!-- Movie Information -->
    <div class="movie-info">
      <film-info
        :movieId="movie.id"
        :movieYear="movie.year"
        :movieImg="movie.filmPicture"
        :movieTitle="movie.name"
        :movieScore="movie.score"
        :moviePlayNum="movie.clickCount"
        :movieType="movie.type"
        :movieLength="movie.filmLength"
        :movieActor="movie.actor"
        :movieLanguage="movie.language"
        :movieReleaseDate="movie.releaseDate"
        :movieReleaseArea="movie.releaseArea"
        :movieDescription="movie.synopsis"
      ></film-info>
    </div>
    <div class="film-demand-filter">
      <!--      选择拼团类型-->
      <select v-model="playType" class="dropdown-menu">
        <option value="">所有放映计划</option>
        <option value="click">正在拼团中</option>
        <option value="task">放映计划</option>
      </select>
    </div>
    <!-- 周边放映-个人点播 -->
    <div
      class="screening-schedule"
      v-for="schedule in movie.personalTasks"
      :key="schedule.id"
    >
      <!-- <h3>Screening Schedule</h3> -->
      <div>
        <div class="screening-schedule-address-title">
          <span>放映地址：</span>
          <span
            class="screening-schedule-status screening-schedule-status-success"
            v-if="schedule.groupBooking === 1"
          >
            <van-icon name="checked" />
            拼团成功
            <!-- <van-icon name="clock" />
            {{
              schedule.spellGroup >= schedule.groupNumber
                ? '拼团成功'
                : '还差' +
                  (schedule.groupNumber - schedule.spellGroup || 'null') +
                  '人拼团'
            }} -->
          </span>
          <span
            class="screening-schedule-status screening-schedule-status-warning"
            v-else-if="schedule.groupBooking === 0"
          >
            <van-icon name="clock" />
            正在拼团中
          </span>
        </div>
        <div class="screening-schedule-point">
          {{ schedule.point }}
        </div>
        <div class="screening-schedule-name">
          {{ schedule.showPointName }}
        </div>
        <div class="screening-schedule-address">
          {{ schedule.showPointAddress }}
        </div>
        <!-- 距离 -->
        <div class="screening-schedule-distance">
          <!-- {{ schedule.meter ? schedule.meter + 'm' : '附近' }} -->
          距您{{ schedule.meter + 'm' }}
        </div>
        <!-- 时间 -->
        <div class="screening-schedule-time-title">放映时间：</div>
        <div class="screening-schedule-time">
          {{ schedule.expectPlayStartTime }} - {{ schedule.expectPlayEndTime }}
        </div>
        <div class="screening-schedule-bottom">
          <div class="left">
            <div>
              已拼
              <span
                :style="{
                  color: schedule.groupBooking === 1 ? '#02b47a' : '#FF6A2A',
                }"
                >{{ schedule.spellGroup }}</span
              >
              人
            </div>
            <!-- <div>还差{{ schedule.groupNumber - schedule.spellGroup }}人拼成</div> -->
            <div style="padding: 0.1rem 0">
              <van-progress
                :color="schedule.groupBooking === 1 ? '#02b47a' : '#FF6A2A'"
                :percentage="
                  ((schedule.spellGroup / schedule.groupNumber) * 100).toFixed(
                    0,
                  )
                "
              />
              <!-- {{ (schedule.spellGroup / schedule.groupNumber * 100).toFixed(2) }}% -->
            </div>
            <div>
              <van-count-down
                :time="getEndTimeDiff(schedule.expectPlayEndTime)"
                format="DD天HH时mm分ss秒"
              />
            </div>
          </div>
          <div class="right">
            <van-button
              :disabled="schedule.joined"
              type="warning"
              style="width: 102px"
              :color="schedule.groupBooking === 1 ? '#02b47a' : '#FF6A2A'"
              round
              @click="joinGroup(schedule)"
            >
              {{ schedule.joined ? '已拼团' : '立即拼团' }}
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 周边放映-集体点播 -->
    <div
      class="screening-schedule"
      v-for="schedule in movie.groupTasks"
      :key="schedule.id"
    >
      <!-- <h3>Screening Schedule</h3> -->
      <div>
        <!-- {{ schedule }} -->
        <div class="screening-schedule-address-title">
          <span>放映地址：</span>
          <!-- <span class="screening-schedule-status"> 社区村代表点播 </span> -->
        </div>
        <div class="screening-schedule-point">
          {{ schedule.point }}
        </div>
        <div class="screening-schedule-name">
          {{ schedule.showPointName }}
        </div>
        <div class="screening-schedule-address">
          {{ schedule.showPointAddress }}
        </div>
        <!-- 距离 -->
        <div class="screening-schedule-distance">
          {{ schedule.meter ? schedule.meter + 'm' : '附近' }}
        </div>
        <!-- 时间 -->
        <div class="screening-schedule-time-title">放映时间：</div>
        <div class="screening-schedule-time">
          {{ schedule.playDatetime }}
        </div>
        <div class="screening-schedule-bottom">
          <div class="left">
            <!-- <div>已有{{ schedule.spellGroup }}人参加</div> -->
            <!-- <div>还差2人拼成</div> -->
            <div>
              <!-- <van-count-down
                :time="getEndTimeDiff(schedule.expectPlayEndTime)"
                format="剩余: DD 天 HH 时 mm 分 ss 秒"
              /> -->
              <!-- {{ calculateRemainingTime(schedule.playDatetime) }} -->
            </div>
          </div>
          <div class="right">
            <van-button
              :disabled="schedule.status > 0"
              type="warning"
              style="width: 102px"
              color="#FF6A2A"
              round
              @click="joinScreeningPlan(schedule)"
            >
              {{ schedule.status ? '已加入' : '我想看' }}
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <van-empty
      description="暂无放映计划或点播记录"
      v-if="!movie.personalTasks.length && !movie.groupTasks.length"
    />
  </div>
</template>

<script>
import filmInfo from './modules/filmInfo'
import { api } from '@/api'
import { Dialog, Progress,Toast } from 'vant'
export default {
  components: {
    filmInfo,
    vanProgress: Progress,
  },
  mounted() {
    const { id } = this.$route.params
    console.log(this.$route.params)
    if (id) {
      this.filmId = id
      this.getMovieInfo()
    }
  },
  data() {
    return {
      // 计划类型
      playType: '',
      filmId: '',
      movie: {
        groupTasks: [],
        clickCount: 17,
        year: 2021,
        director: '鲁坚',
        filmLength: 127,
        productionCompany: null,
        synopsis: '',
        type: '故事片',
        labels: '情感伦理,历史,传记,西部',
        personalTasks: [
          {
            expectPlayEndTime: '2024-09-22',
            lng: 133.770708,
            meter: 0,
            spellGroup: 0,
            groupNumber: 1,
            groupBooking: 0,
            joined: false,
            showPointAddress: '清波门社区放映地址',
            expectPlayStartTime: '2024-09-16',
            showPointName: '清波门社区放映点',
            showPointId: '1827598534569971713',
            expectPlayPeriod: null,
            taskId: '1827884135105077249',
            lat: 35.130815,
          },
        ],
        score: 5.0,
        stagePhoto: null,
        starrings:
          '阿旺仁青,索朗卓嘎,旺卓措,洛桑念扎,扎西旺堆,卓杰泽仁,嘉增达杰,洛丹,拉旺罗布,扎姆拉',
        name: '布德之路',
        id: '10',
        boxOffice: 0.0,
        viewCount: 0,
        filmPicture: 'https://www.dfcc.org.cn/filmimage/sync12/20220208091600_ad16a3e6887c11eca84b588a5aafb020.jpg',
        scriptwriter: '鲁坚,杨年华,杨宓',
      },
    }
  },
  methods: {
    // 获取电影信息
    getMovieInfo() {
      api
        .getFilmAroundDetail(this.filmId, {
          showPointId: this.$store.state.userPoint.id,
          category: this.playType,
        })
        .then((res) => {
          // console.log({ AAAAAAA: res.result })
          const { success, result } = res
          if (!success) return
          this.movie = result
        })
    },
    // 根据结束时间算出距离结束时间的时间戳
    getEndTimeDiff(endTime) {
      const now = new Date()
      const end = new Date(endTime)
      const diff = end.getTime() - now.getTime()
      // console.log({ diff })
      return diff
    },
    // 点击拼团
    joinGroup(schedule) {
      console.log('joinGroup')
      Dialog.confirm({
        title: '确定要加入该拼团吗？',
        confirmButtonColor: '#5856d6',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: (action) => {
          if (action === 'confirm') {
            console.log('confirm')
            api.joinGroup(schedule.taskId).then((res) => {
              console.log(res)
              const { success, message } = res
              if(success){
                Toast('加入成功')
                setTimeout(() => {
                this.getMovieInfo()
                }, 1000)
              }else{
                Toast(message)
              }
              
            })
          }
        },
      })
    },
    // 加入放映计划
    joinScreeningPlan(schedule) {
      console.log('joinScreeningPlan')
      Dialog.confirm({
        title: '确定要加入该放映计划吗？',
        confirmButtonColor: '#5856d6',
        // message: '加入后，您将成为该场次的唯一拼团成员，并有机会获得奖品。',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        api.personal.getPersonalWantSee({
          planId:schedule.taskId,
          filmIds:[this.filmId]
        }).then((res) => {
          console.log(res)
          const { success, message } = res
          if(success){
                Toast('加入成功')
                setTimeout(() => {
                this.getMovieInfo()
                }, 1000)
              }else{
                Toast(message)
              }
        })
      })
    },
    // 根据给的时间算出剩余时间
    calculateRemainingTime(endTime) {
      const now = new Date()
      const end = new Date(endTime)
      const diff = end.getTime() - now.getTime()

      if (diff <= 0) {
        return '已结束'
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))

      return `${days}天${hours}小时${minutes}分钟`
    },
  },

  watch: {
    playType(val) {
      console.log(val)
      this.getMovieInfo()
    },
  },
}
</script>

<style scoped lang="less">
.film-around-detail {
  /* Add your styles here */
}

.movie-info {
  /* Add your styles here */
  padding: 12px;
}

.screening-schedule {
  /* Add your styles here */
  margin: 12px;
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  color: @color-gray;
  font-size: @font-size-base;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);

  &-address {
    &-title {
      display: flex;
      justify-content: space-between;
      font-size: @font-size-base;
      margin: 4px 0;
    }
  }

  &-name {
    font-size: 18px;
    font-weight: bold;
    font-family: @font-family-base;
    color: #363a44;
    margin: 4px 0;
  }

  &-address {
    font-size: @font-size-base;
    margin: 4px 0;
  }

  &-status {
    color: #ff6a2a;
    &-success {
      color: #57c54a;
    }
  }

  &-time {
    margin: 4px 0;
    font-size: 18px;
    font-weight: bold;
    font-family: @font-family-base;
    color: #363a44;

    &-title {
      margin-top: 24px;
    }
  }

  &-bottom {
    margin: 8px 0 4px 0;
    height: 48px;
    display: flex;
    justify-content: space-between;
  }

  &-distance {
    color: @color-primary;
    margin: 4px 0;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 60%;
  }
}
.dropdown-menu {
  width: 128px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6e8eb;
  margin: 0 12px;
}
</style>
